<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-container fluid>
            <!-- <v-row no-gutters>
             <v-col cols="10"></v-col>
             <v-col cols="2">
                 <v-btn cols="2" class="success" @click.stop="onDownload">
                     <v-icon>get_app</v-icon>
                     Export
                 </v-btn>
             </v-col>
         </v-row>-->
            <v-expansion-panels multiple hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <v-row col="12">
                                <v-row align="start" justify="start">

                                    <v-col class="d-flex mt-5" cols="12" sm="4">
                                        <v-autocomplete v-model="serverParams.ReportType"
                                                        :items="ReportTypes"
                                                        close-on-content-click
                                                        chips
                                                        clearable
                                                        dense
                                                        outlined
                                                        color="blue-grey lighten-2"
                                                        :label="$t('report_type')"
                                                        item-text="value"
                                                        item-value="id"
                                                        class="pa-0">
                                            <!-- Template for render selected data -->
                                            <template slot="selection"
                                                      slot-scope="data">
                                                <v-chip :input-value="data.selected"
                                                        class="chip--select ma-0 ">
                                                    {{ data.item.value }}
                                                </v-chip>
                                            </template>
                                            <!-- Template for render data when the select is expanded -->
                                            <template slot="item"
                                                      slot-scope="data">
                                                <!-- Divider and Header-->
                                                <template v-if="typeof data.item !== 'object'">
                                                    <div v-text="data.item" />
                                                </template>
                                                <!-- Normal item -->
                                                <template v-else>
                                                    <div>
                                                        <div v-html="data.item.value"></div>
                                                    </div>
                                                </template>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <!-- <v-col class="d-flex" cols="12" sm="3">
        <v-autocomplete v-model="serverParams.ReportCat"
                        :items="ReportCats"
                        label="Pilgrimage Name"
                        clearable
                        single-line
                        item-text="value"
                        item-value="value"
        ></v-autocomplete>
    </v-col>-->
                                    <!-- <v-col class="d-flex" cols="12" sm="2">
        <v-autocomplete v-model="serverParams.isIndian"
                        :items="ReportInclusionCats"
                        label="Inclusion"
                        clearable
                        single-line
                        item-text="value"
                        item-value="id"
        ></v-autocomplete>
    </v-col>-->                     <v-select v-if="reportPortOfEntryByLand || reportIsPOEMediumWise  || reportCountryVsLand || reportIsPOECountryWise || reportIsPOECountryLandAndAir"
                                              class="mt-8"
                                              :items="customYear"
                                              dense
                                                        outlined
                                              :label="$t('select_year')"
                                              v-model="yearFromDate"
                                              prepend-inner-icon="event"
                                              ></v-select>
                                    <v-select v-if="!reportIsLOSWise && !reportPortOfEntryByLand && !reportIsPOEMediumWise && !reportCountryVsLand && !reportIsPOECountryWise && !reportIsPOECountryLandAndAir"
                                              class="mt-8"
                                              :items="customYear"
                                              dense
                                                        outlined
                                              :label="$t('from_date')"
                                              v-model="yearFromDate"
                                              prepend-inner-icon="event"
                                              ></v-select>
                                    

                                    <v-select v-if="!reportIsLOSWise && !reportPortOfEntryByLand && !reportIsPOEMediumWise && !reportCountryVsLand && !reportIsPOECountryWise && !reportIsPOECountryLandAndAir"
                                              class="mt-8"
                                              :items="customYear"
                                              :label="$t('to_date')"
                                              dense
                                                        outlined
                                              v-model="yearToDate"
                                              prepend-inner-icon="event"
                                              ></v-select>

                                    <v-col class="d-flex mt-5" cols="12" sm="4"
                                           v-if="reportIsLOSWise">
                                        <v-menu v-model="snackbar.menu1"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="serverParams.FromDate"
                                                              :label="$t('from_date')"
                                                              clearable
                                                              dense
                                                        outlined
                                                              prepend-inner-icon="event"
                                                              readonly
                                                              v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="serverParams.FromDate">

                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="d-flex mt-5" cols="12" sm="4" v-if="reportIsLOSWise">
                                        <v-menu v-model="snackbar.menu2"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="serverParams.ToDate"
                                                              :label="$t('to_date')"
                                                              clearable
                                                              dense
                                                        outlined
                                                              prepend-inner-icon="event"
                                                              readonly
                                                              v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="serverParams.ToDate">

                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="d-flex text-center" cols="12" sm="12">
                                        <v-spacer></v-spacer>
                                        <v-btn class="mx-2" dark small color="primary" @click="loadItems">
                                            <v-icon dark>search</v-icon>
                                            {{$t('search')}}
                                        </v-btn>
                                        <JsonExcelVue v-if="serverParams.ReportType === 'ByAgeGroup'" :fetch="fetchData" is-custom-header is-custom-data :customHeader="customHeader">
                                            <v-btn small class="success mx-4">
                                                <v-icon>get_app</v-icon>
                                                {{$t('download_to_excel')}}
                                            </v-btn>

                                        </JsonExcelVue>
                                        <v-btn v-else @click.stop="onDownload" small class="success mx-4">
                                            <v-icon>get_app</v-icon>
                                            {{$t('download_to_excel')}}
                                        </v-btn>

                                        <v-spacer></v-spacer>
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <p style="text-align:center"><b>{{this.header_title}} </b></p>
            <div v-if="reportIsAgeGroupWise" class="table-container">
                <div class="d-flex justify-center" v-if="isLoading">
                    <v-progress-circular :size="50"
                                         color="primary"
                                         indeterminate></v-progress-circular>

                </div>
                <table class="myCustomTable">
                    <thead>
                        <tr>
                            <td rowspan="3">
                                Year
                            </td>
                            <td rowspan="3">
                                Total
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6"> Age Group</td>
                        </tr>
                        <tr>
                            <td>0-15</td>
                            <td>16-30</td>
                            <td>31-45</td>
                            <td>46-60</td>
                            <td>60+</td>
                            <td>Not specified</td>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(row,index) in rows">
                            <tr :key="index">
                                <td rowspan="3">{{row.year | moment("YYYY")}}</td>
                            </tr>
                            <template v-for="(secondRow,counter) in row.data">
                                <tr :key="secondRow.year+'column' + counter">
                                    <td>{{secondRow.total}}</td>
                                    <td>{{secondRow.age_0_15_Group}}</td>
                                    <td>{{secondRow.age_16_30_Group}}</td>
                                    <td>{{secondRow.age_31_45_Group}}</td>
                                    <td>{{secondRow.age_46_60_Group}}</td>
                                    <td>{{secondRow.age_60_Group}}</td>
                                    <td>{{secondRow.not_Sp}}</td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
            <div v-else-if="reportIsPOVWise" class="table-container">
                <div class="d-flex justify-center" v-if="isLoading">
                    <v-progress-circular :size="50"
                                         color="primary"
                                         indeterminate></v-progress-circular>

                </div>
                <table class="myCustomTable">
                    <thead>
                        <tr>
                            <td> Year</td>
                            <td> Holiday Pleasure</td>
                            <td> Trekking & Mountaineering</td>
                            <td> Business</td>
                            <td> Pilgrimage</td>
                            <td> Official</td>
                            <td> Conv./ Conf.</td>
                            <td> Others</td>
                            <td> Not Specified</td>
                            <td> Total</td>
                        </tr>
                    </thead>
                    <tbody>
                        <template>
                            <tr v-for="(pov,index) in rows.data" :key="index">
                                <td>{{pov.years}}</td>
                                <td>{{pov.holidayPleasure}}</td>
                                <td>{{pov.trekkingMountaineering}}</td>
                                <td>{{pov.business}}</td>
                                <td>{{pov.pilgrimage}}</td>
                                <td>{{pov.official}}</td>
                                <td>{{pov.seminar}}</td>
                                <td>{{pov.others}}</td>
                                <td>--</td>
                                <td>{{pov.total}}</td>
                            </tr>
                        </template>

                    </tbody>
                </table>
            </div>
            <div v-else >
                <vue-good-table :columns="columns"
                                :rows="rows"
                                mode="remote"
                                :isLoading.sync="isLoading"
                                styleClass="vgt-table condensed"
                                style="z-index:1"
                                max-height="500px"
                                :fixed-header="true"
                                >
                    <template slot="table-column" slot-scope="props">
                        <span>
                            {{ $t(props.column.label) }}
                        </span>
                    </template>
                    <template slot="table-row">

                    </template>
                </vue-good-table>
            </div>
               
            

            <v-snackbar v-model="snackbar.snackbar"
                        color="success"
                        :timeout="snackbar.timeout"
                        top>
                {{ callbackResponse.message }}
                <v-btn dark
                       text
                       @click="snackbar.snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        </v-container>
        
        <v-row class="mt-7">
            <div class="col-12" style="color: gray; text-align: right">
                {{$t('source')}} : {{$t('department_of_immigration')}}
            </div>
        </v-row>
    </v-content>
</template>

<script>
    import axios from "axios";
    import JsonExcelVue from "@/components/ImportExport/JsonExcel.vue"

    export default {
        name: "ArrivalTourist-ArrivalReport",
        props: [
            'moduleName'
        ],
        components: { JsonExcelVue },
        computed: {
            customYear() {
                let year = []
                for (let i = 0; i < 99; i++) {
                    year.push(`20${i < 10 ? '0' : ''}${i}`)
                }
                return year
            },
            reportIsYearWise() {
                return this.serverParams.ReportType === 'ByYear';
            },
            reportIsGenderWise() {
                return this.serverParams.ReportType === 'BySex';
            },
            reportIsLOSWise() {
                return this.serverParams.ReportType === 'ALS';
            },
            reportIsAgeGroupWise() {
                return this.serverParams.ReportType === 'ByAgeGroup';
            },
            reportCountryVsLand() {
                return this.serverParams.ReportType === 'ByCountryVsLand';
            },
            reportIsPOECountryWise() {
                return this.serverParams.ReportType === 'ByCountryVsAir';
            },
            reportIsPOECountryLandAndAir() {
                return this.serverParams.ReportType === 'ByCountryVsAirVsLand';
            },
            reportPortOfEntryByLand() {
                return this.serverParams.ReportType === 'ByPortOfEntryByLand';
            },
            reportIsPOEMediumWise() {
                return this.serverParams.ReportType === 'ByPortOfEntryByAir';
            },
            reportIsPOVWise() {
                return this.serverParams.ReportType === 'ByPurposeOfVisit';
            },
            items() {
                return [
                    {
                        text: this.$t('reports'),
                        exact: true,
                        to: '/reportModules'
                    },
                    {
                        text: this.$t('tourist_arrival_report_menu'),
                        exact: true,
                        to: '/reportModules/reportTourist-Arrival'
                    },
                    {
                        text: this.$t('arrival_report'),
                        disabled: true,
                        exact: true
                    }
                ]
            }
        },

        methods: {
            getNow: function () {
                const today = new Date();
                const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                /* const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();*/
                this.serverParams.FromDate = date;
                this.serverParams.ToDate = date;
            },
            async getFilterData() {
                try {
                    const { data } = await axios.get('Pilgrimage/DDLPilGrimageListAsync')
                    this.ReportCats = data
                } catch (e) {
                    console.log(e)
                }
            },
            formResponse(data) {
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },
            async fetchData() {
                await this.loadItems();
                this.customHeader = [
                    {
                        name: "Year"
                    },
                    {
                        name: "Total"
                    },
                    {
                        name: "Age Group",
                        children: ["0-15", "16-30", "31-45", "46-60", "60+", "Not Specified"]
                    }
                ]
                let customData = []
                this.rows.map(res => {
                    let cData = []
                    cData.title = res.data[0].year
                    cData.nestedData = res.data.map(res => {
                        const newObj = {
                            total: res.total,
                            age_0_15_Group: res.age_0_15_Group,
                            age_16_30_Group: res.age_16_30_Group,
                            age_31_45_Group: res.age_31_45_Group,
                            age_46_60_Group: res.age_46_60_Group,
                            age_60_Group: res.age_60_Group,
                            not_Sp: res.not_Sp,
                        }
                        return Object.values(newObj)
                    })
                    customData.push(cData)
                })
                return customData
            },
            async onDownload() {
                if (this.serverParams.ReportType === 'ByYear') {
                    this.isLoading = true;
                    let param = {
                        ToDate: this.yearToDate + '-12-31',
                        FromDate: this.yearFromDate + '-01-01',
                        ReportType: this.serverParams.ReportType,
                    };
                    await axios({
                        url: 'Immigration/DownloadImmigrationyearWiseReportListAsyc',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'ImmigrationyearWiseReportListAsyc.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        this.isLoading = false;
                    });
                }
                else if (this.serverParams.ReportType === 'BySex') {
                    this.isLoading = true;
                    let param = {
                        ToDate: this.yearToDate + '-12-31',
                        FromDate: this.yearFromDate + '-01-01',
                        ReportType: this.serverParams.ReportType,
                    };
                    await axios({
                        url: 'Immigration/DownloadImmigrationSexWiseReportListAsyc',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'ImmigrationSexWiseReportListAsyc.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        this.isLoading = false;
                    });
                }
                else if (this.serverParams.ReportType === 'ALS') {
                    this.isLoading = true;
                    let param = {
                        ToDate: this.serverParams.ToDate,
                        FromDate: this.serverParams.FromDate
                    };
                    await axios({
                        url: 'Immigration/DownloadArrivalAndAverageLengthOfStayReportListAsyc',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'ArrivalAndAverageLengthOfStayReportListAsyc.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        this.isLoading = false;
                    });
                }
                else if (this.serverParams.ReportType === 'ByAgeGroup') {
                    this.isLoading = true;
                    let param = {
                        ToDate: this.yearToDate + '-12-31',
                        FromDate: this.yearFromDate + '-01-01',
                        ReportType: this.serverParams.ReportType,
                    };
                    await axios({
                        url: 'Immigration/DownloadImmigrationAgeGroupWiseReportListAsyc',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'ImmigrationAgeGroupWiseReportListAsyc.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        this.isLoading = false;
                    });
                }
                else if (this.serverParams.ReportType === 'ByCountryVsLand' || this.serverParams.ReportType === 'ByCountryVsAir') {
                    this.isLoading = true;
                    let param = {
                        ToDate: this.yearToDate + '-12-31',
                        FromDate: this.yearFromDate + '-01-01',
                        ReportType: this.serverParams.ReportType,
                    };
                    await axios({
                        url: 'Immigration/DownloadImmigrationPortofEntryByCountryWiseReportListAsyc',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'ImmigrationPortofEntryByCountryWiseReportListAsyc.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        this.isLoading = false;
                    });
                }
                else if (this.serverParams.ReportType === 'ByCountryVsAirVsLand') {
                    this.isLoading = true;
                    let param = {
                        ToDate: this.yearToDate + '-12-31',
                        FromDate: this.yearFromDate + '-01-01',
                        ReportType: this.serverParams.ReportType,
                    };
                    await axios({
                        url: 'Immigration/DownloadImmigrationPortofEntryByCountryWiseReportListAsyc',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'ImmigrationPortofEntryByCountryWiseReportListAsyc.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        this.isLoading = false;
                    });
                }
                else if (this.serverParams.ReportType === 'ByPortOfEntryByLand' || this.serverParams.ReportType === 'ByPortOfEntryByAir') {
                    this.isLoading = true;
                    let param = {
                        FromDate: this.yearFromDate + '-01-01',
                        ReportType: this.serverParams.ReportType,
                    };
                    await axios({
                        url: 'Immigration/DownloadImmigrationPortofEntryWiseReportListAsyc',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'ImmigrationPortofEntryWiseReportListAsyc.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        this.isLoading = false;
                    });
                }
                else if (this.serverParams.ReportType === 'ByPurposeOfVisit') {
                    this.isLoading = true;
                    let param = {
                        FromDate: this.yearFromDate + '-01-01',
                        ToDate: this.yearToDate + '-12-31',
                        ReportType: this.serverParams.ReportType,
                    };
                    await axios({
                        url: 'Immigration/DownloadImmigrationPurposeOfVisitWiseReportListAsyc',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'ImmigrationPurposeOfVisitWiseReportListAsyc.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        this.isLoading = false;
                    });
                }
                else {
                    this.isLoading = true;
                    let param = {
                        ToDate: this.yearToDate + '-12-31',
                        FromDate: this.yearFromDate + '-01-01',
                        ReportType: this.serverParams.ReportType,
                    };
                    await axios({
                        url: 'Immigration/DownloadImmigrationMonthWiseReportListAsyc',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'ImmigrationMonthWiseReportListAsyc.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        this.isLoading = false;
                    });
                }
                //await axios({
                //    url: 'Report/DownloadSample',
                //    method: 'POST',
                //    responseType: 'blob'
                //}).then((response) => {
                //    const url = window.URL.createObjectURL(new Blob([response.data]));
                //    const link = document.createElement('a');
                //    link.href = url;
                //    link.setAttribute('download', 'PilgrimageFile.xlsx'); //or any other extension
                //    document.body.appendChild(link);
                //    link.click();
                //});
            },
            onResponse(data) {
                if (data == true) {
                    this.dialogDelete = false
                    this.loadItems();
                } else {
                    this.dialogDelete = false
                }
            },
            async loadItems() {
                this.header_title = '';
                this.isLoading = true;
                if (this.reportIsYearWise) {
                    this.header_title = 'TOURIST ARRIVAL BY YEAR';
                    let param = {
                        ToDate: this.yearToDate + '-12-31',
                        FromDate: this.yearFromDate + '-01-01',
                        ReportType: this.serverParams.ReportType,
                    };
                    await axios.post('Immigration/GetImmigrationyearWiseReportListAsyc', param)
                        .then(response => {
                            this.rows = response.data;
                            //this.totalRecords = response.data.totalCount;
                            this.columns = []
                            this.columns.push(
                                {
                                    label: 'year',
                                    field: 'year'
                                },
                                {
                                    label: 'third_country',
                                    field: 'thirdCountry'
                                },
                                {
                                    label: 'indian',
                                    field: 'indian'
                                },
                                {
                                    label: 'total',
                                    field: 'total'
                                },
                                {
                                    label: 'change',
                                    field: 'change'
                                },
                            )
                            this.isLoading = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.isLoading = false;
                        });
                } else if (this.reportIsGenderWise) {
                    this.isLoading = true
                    this.header_title = 'TOURIST ARRIVAL BY SEX';
                    let param = {
                        ToDate: this.yearToDate + '-12-31',
                        FromDate: this.yearFromDate + '-01-01',
                        ReportType: this.serverParams.ReportType,
                    };
                    await axios.post('Immigration/GetImmigrationSexWiseReportListAsyc', param)
                        .then(response => {
                            this.rows = response.data;
                            //this.totalRecords = response.data.totalCount;
                            this.columns = []
                            this.columns = [
                                {
                                    label: 'year',
                                    field: 'year'
                                },
                                {
                                    label: 'male',
                                    field: 'male'
                                },
                                {
                                    label: 'change_in_male',
                                    field: 'changeMale'
                                },
                                {
                                    label: 'female',
                                    field: 'female'
                                },
                                {
                                    label: 'change_in_female',
                                    field: 'changeFemale'
                                },
                                {
                                    label: 'others',
                                    field: 'others'
                                },
                                {
                                    label: 'change_in_others',
                                    field: 'changeOthers'
                                },
                                {
                                    label: 'total',
                                    field: 'total'
                                },
                            ]
                            this.isLoading = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.isLoading = false;
                        });
                } else if (this.reportIsLOSWise) {
                    this.isLoading = true;
                    this.header_title = 'TOURIST ARRIVAL AND AVRAGE LENGTH OF STAY';
                    let param = {
                        ToDate: this.serverParams.ToDate,
                        FromDate: this.serverParams.FromDate,
                    };
                    await axios.post('Immigration/GetArrivalAndAverageLengthOfStayReportListAsyc', param)
                        .then(response => {
                            this.rows = response.data;
                            //this.totalRecords = response.data.totalCount;
                            this.columns = []
                            this.columns = [
                                {
                                    label: 'year',
                                    field: 'year'
                                },
                                {
                                    label: 'number',
                                    field: 'number'
                                },
                                {
                                    label: 'average_length_of_stay',
                                    field: 'averageLengthOfStay'
                                },
                                //{
                                //    label: 'Aggregate Length of Stay',
                                //    field: 'aggregateDaysOfStay'
                                //},
                            ]
                            this.snackbar.loading = false;
                            this.isLoading = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.isLoading = false;
                        });
                } else if (this.reportIsAgeGroupWise) {
                    this.isLoading = true;
                    this.header_title = 'TOURIST ARRIVAL BY AGE GROUPS';
                    let param = {
                        ToDate: this.yearToDate + '-12-30',
                        FromDate: this.yearFromDate + '-01-01',
                        ReportType: this.serverParams.ReportType,
                    };
                    await axios.post('Immigration/GetImmigrationAgeGroupWiseReportListAsyc', param)
                        .then(response => {
                            this.rows = response.data;
                            //this.totalRecords = response.data.totalCount;
                            this.isLoading = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.isLoading = false;
                        });
                } else if (this.reportIsPOECountryWise) {
                    this.isLoading = true;
                    this.header_title = 'TOURIST ARRIVAL BY COUNTRY AND MONTH (AIR)';
                    let param = {
                        FromDate: this.yearFromDate + '-01-01',
                        ReportType: this.serverParams.ReportType,
                    };
                    await axios.post('Immigration/GetImmigrationPortofEntryByCountryWiseReportListAsyc', param)
                        .then(response => {
                            this.rows = response.data;
                            this.columns = []
                            this.columns = [
                                {
                                    label: 'country',
                                    field: 'country'
                                },
                                {
                                    label: 'january',
                                    field: 'january'
                                },
                                {
                                    label: 'february',
                                    field: 'february'
                                }, {
                                    label: 'march',
                                    field: 'march'
                                }, {
                                    label: 'april',
                                    field: 'april'
                                }, {
                                    label: 'may',
                                    field: 'may'
                                }, {
                                    label: 'june',
                                    field: 'june'
                                }, {
                                    label: 'july',
                                    field: 'july'
                                }, {
                                    label: 'august',
                                    field: 'august'
                                }, {
                                    label: 'september',
                                    field: 'september'
                                }, {
                                    label: 'october',
                                    field: 'october'
                                }, {
                                    label: 'november',
                                    field: 'november'
                                }, {
                                    label: 'december',
                                    field: 'december'
                                }, {
                                    label: 'total',
                                    field: 'total'
                                }
                            ]
                            //this.totalRecords = response.data.totalCount;
                            this.isLoading = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.isLoading = false;
                        });
                }
                else if (this.reportCountryVsLand) {
                    this.isLoading = true;
                    this.header_title = 'TOURIST ARRIVAL BY COUNTRY AND MONTH (LAND)';
                    let param = {
                        FromDate: this.yearFromDate + '-01-01',
                        ReportType: this.serverParams.ReportType,
                    };
                    await axios.post('Immigration/GetImmigrationPortofEntryByCountryWiseReportListAsyc', param)
                        .then(response => {
                            this.rows = response.data;
                            this.columns = []
                            this.columns = [
                                {
                                    label: 'country',
                                    field: 'country'
                                },
                                {
                                    label: 'january',
                                    field: 'january'
                                },
                                {
                                    label: 'february',
                                    field: 'february'
                                }, {
                                    label: 'march',
                                    field: 'march'
                                }, {
                                    label: 'april',
                                    field: 'april'
                                }, {
                                    label: 'may',
                                    field: 'may'
                                }, {
                                    label: 'june',
                                    field: 'june'
                                }, {
                                    label: 'july',
                                    field: 'july'
                                }, {
                                    label: 'august',
                                    field: 'august'
                                }, {
                                    label: 'september',
                                    field: 'september'
                                }, {
                                    label: 'october',
                                    field: 'october'
                                }, {
                                    label: 'november',
                                    field: 'november'
                                }, {
                                    label: 'december',
                                    field: 'december'
                                }, {
                                    label: 'total',
                                    field: 'total'
                                }
                            ]
                            //this.totalRecords = response.data.totalCount;
                            this.isLoading = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.isLoading = false;
                        });
                }
                else if (this.reportIsPOECountryLandAndAir) {
                    this.isLoading = true;
                    this.header_title = 'TOURIST ARRIVAL BY COUNTRY AND MONTH(COUNTRY/AIR/LAND)';
                    let param = {
                        FromDate: this.yearFromDate + '-01-01',
                        ReportType: this.serverParams.ReportType,
                    };
                    axios.post('Immigration/GetImmigrationPortofEntryByLandAirCountryListAsyc', param)
                        .then(response => {
                            this.rows = response.data;
                            this.columns = []
                            this.columns = [
                                {
                                    label: 'country',
                                    field: 'country'
                                },
                                {
                                    label: 'january',
                                    field: 'january'
                                },
                                {
                                    label: 'february',
                                    field: 'february'
                                }, {
                                    label: 'march',
                                    field: 'march'
                                }, {
                                    label: 'april',
                                    field: 'april'
                                }, {
                                    label: 'may',
                                    field: 'may'
                                }, {
                                    label: 'june',
                                    field: 'june'
                                }, {
                                    label: 'july',
                                    field: 'july'
                                }, {
                                    label: 'august',
                                    field: 'august'
                                }, {
                                    label: 'september',
                                    field: 'september'
                                }, {
                                    label: 'october',
                                    field: 'october'
                                }, {
                                    label: 'november',
                                    field: 'november'
                                }, {
                                    label: 'december',
                                    field: 'december'
                                }, {
                                    label: 'total',
                                    field: 'total'
                                }
                            ]
                            //this.totalRecords = response.data.totalCount;
                            this.isLoading = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.isLoading = false;
                        });
                }
                else if (this.reportPortOfEntryByLand) {
                    this.isLoading = true;
                    this.header_title = 'TOURIST ARRIVAL BY ENTRY POINTS (LAND)';
                    let param = {
                        FromDate: this.yearFromDate + '-01-01',
                        ReportType: this.serverParams.ReportType,
                    };
                    axios.post('Immigration/GetImmigrationPortofEntryWiseReportListAsyc', param)
                        .then(response => {
                            this.rows = response.data;
                            console.log('this.PortOfEntry', this.rows);
                            //this.totalRecords = response.data.totalCount;
                            this.columns = []
                            this.columns = [
                                {
                                    label: 'entry_point',
                                    field: 'entryPoint'
                                },
                                {
                                    label: 'january',
                                    field: 'january'
                                },
                                {
                                    label: 'february',
                                    field: 'february'
                                }, {
                                    label: 'march',
                                    field: 'march'
                                }, {
                                    label: 'april',
                                    field: 'april'
                                }, {
                                    label: 'may',
                                    field: 'may'
                                }, {
                                    label: 'june',
                                    field: 'june'
                                }, {
                                    label: 'july',
                                    field: 'july'
                                }, {
                                    label: 'august',
                                    field: 'august'
                                }, {
                                    label: 'september',
                                    field: 'september'
                                }, {
                                    label: 'october',
                                    field: 'october'
                                }, {
                                    label: 'november',
                                    field: 'november'
                                }, {
                                    label: 'december',
                                    field: 'december'
                                }, {
                                    label: 'total',
                                    field: 'total'
                                }
                            ]
                            this.isLoading = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.isLoading = false;
                        });
                }
                else if (this.reportIsPOEMediumWise) {
                    this.isLoading = true;
                    this.header_title = 'TOURIST ARRIVAL BY ENTRY POINTS (AIR) ';
                    let param = {
                        FromDate: this.yearFromDate + '-01-01',
                        ReportType: this.serverParams.ReportType,
                    };
                    axios.post('Immigration/GetImmigrationPortofEntryWiseReportListAsyc', param)
                        .then(response => {
                            this.rows = response.data;
                            console.log('this.PortOfEntry', this.rows);
                            //this.totalRecords = response.data.totalCount;
                            this.columns = []
                            this.columns = [
                                {
                                    label: 'entry_point',
                                    field: 'entryPoint'
                                },
                                {
                                    label: 'january',
                                    field: 'january'
                                },
                                {
                                    label: 'february',
                                    field: 'february'
                                }, {
                                    label: 'march',
                                    field: 'march'
                                }, {
                                    label: 'april',
                                    field: 'april'
                                }, {
                                    label: 'may',
                                    field: 'may'
                                }, {
                                    label: 'june',
                                    field: 'june'
                                }, {
                                    label: 'july',
                                    field: 'july'
                                }, {
                                    label: 'august',
                                    field: 'august'
                                }, {
                                    label: 'september',
                                    field: 'september'
                                }, {
                                    label: 'october',
                                    field: 'october'
                                }, {
                                    label: 'november',
                                    field: 'november'
                                }, {
                                    label: 'december',
                                    field: 'december'
                                }, {
                                    label: 'total',
                                    field: 'total'
                                }
                            ]
                            this.isLoading = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.isLoading = false;
                        });
                }
                else if (this.reportIsPOVWise) {
                    this.isLoading = true;
                    this.header_title = 'TOURIST ARRIVAL BY PURPOSE OF VISIT';
                    let param = {
                        FromDate: this.yearFromDate + '-01-01',
                        ToDate: this.yearToDate + '-12-31',
                    };
                    axios.post('Immigration/GetImmigrationPurposeOfVisitWiseReportListAsyc', param)
                        .then(response => {
                            this.rows = response
                            console.log('dsfsdfsdfsdf', this.rows)
                            this.isLoading = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.isLoading = false;
                        });
                } else {
                    this.isLoading = true;
                    if (this.serverParams.ReportType === 'ByAir') {
                        this.header_title = 'TOURIST ARRIVAL BY MONTH (AIR)';
                    }
                    else if (this.serverParams.ReportType === 'ByLand') {
                        this.header_title = 'TOURIST ARRIVAL BY MONTH (LAND)';
                    }
                    else if (this.serverParams.ReportType === 'IndianByAir') {
                        this.header_title = 'INDIAN TOURIST ARRIVAL BY MONTH (AIR)';
                    }
                    else if (this.serverParams.ReportType === 'IndianByLand') {
                        this.header_title = 'INDIAN TOURIST ARRIVAL BY MONTH (LAND)';
                    }
                    else if (this.serverParams.ReportType === 'ExcludeIndian') {
                        this.header_title = 'TOURIST ARRIVAL BY MONTH (Excluding Indian Citizens)';
                    }
                    else {
                        this.header_title = 'TOURIST ARRIVAL BY MONTH (Indian Only)';
                    }
                    let param = {
                        ToDate: this.yearToDate + '-12-31',
                        FromDate: this.yearFromDate + '-01-01',
                        ReportType: this.serverParams.ReportType,
                    };
                    axios.post('Immigration/GetImmigrationMonthWiseReportListAsyc', param)
                        .then(response => {
                            this.rows = response.data;
                            //this.totalRecords = response.data.totalCount;
                            this.columns = []
                            this.columns = [
                                {
                                    label: 'year',
                                    field: 'year'
                                },
                                {
                                    label: 'january',
                                    field: 'january'
                                },
                                {
                                    label: 'february',
                                    field: 'february'
                                }, {
                                    label: 'march',
                                    field: 'march'
                                }, {
                                    label: 'april',
                                    field: 'april'
                                }, {
                                    label: 'may',
                                    field: 'may'
                                }, {
                                    label: 'june',
                                    field: 'june'
                                }, {
                                    label: 'july',
                                    field: 'july'
                                }, {
                                    label: 'august',
                                    field: 'august'
                                }, {
                                    label: 'september',
                                    field: 'september'
                                }, {
                                    label: 'october',
                                    field: 'october'
                                }, {
                                    label: 'november',
                                    field: 'november'
                                }, {
                                    label: 'december',
                                    field: 'december'
                                }, {
                                    label: 'total',
                                    field: 'total'
                                }
                            ]
                            this.isLoading = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.isLoading = false;
                        });
                }


            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },

            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.historyData.editItemIndex = item.personInformationID
            },

            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Mountain/DeleteHistoryInformationByIDAsync/" + props.personInformationID;
            },
        },
        mounted() {
            this.getNow()
            this.getFilterData()
            /* this.loadItems()*/
        },
        data() {
            return {
                tab: 'tab-monthlyReport',
                customHeader: [],
                header_title: '',
                callbackResponse: {
                    timeout: 6000
                },

                //items: [
                //    {
                //        text: this.$t('reports'),
                //        exact: true,
                //        to: '/reportModules'
                //    },
                //    {
                //        text: this.$t('tourist_arrival_report_menu'),
                //        exact: true,
                //        to: '/reportModules/reportTourist-Arrival'
                //    },
                //    {
                //        text: this.$t('arrival_report'),
                //        disabled: true,
                //        exact: true
                //    }],
                columns: [],
                rows: [],
                ReportTypes: [
                    { id: 'ByYear', value: this.$t('year_wise') },
                    { id: 'ALS', value: this.$t('average_length_of_stay') },
                    { divider: true },
                    { header: this.$t('port_of_entry_wise_(POE)') },
                    { id: 'ByPortOfEntryByLand', value: this.$t('poe_land') },
                    { id: 'ByPortOfEntryByAir', value: this.$t('poe_air') },
                    { id: 'ByCountryVsLand', value: this.$t('poe_country_land') },
                    { id: 'ByCountryVsAir', value: this.$t('poe_country_air') },
                    { id: 'ByCountryVsAirVsLand', value: this.$t('poe_country_air_land') },
                    { divider: true },
                    { header: this.$t('age_sex') },
                    { id: 'BySex', value: this.$t('gender_wise') },
                    { id: 'ByAgeGroup', value: this.$t('age_group_wise') },
                    { divider: true },
                    { header: 'Arrival Medium' },
                    { id: 'ByAir', value: 'Through Air' },
                    { id: 'ByLand', value: 'Through Land' },
                    { id: 'IndianByAir', value: 'Indian Through Air' },
                    { id: 'IndianByLand', value: 'Indian Through Land' },
                    { id: 'ExcludeIndian', value: 'Indian Excluded' },
                    { id: 'Indian', value: 'Indian Only' },
                    { divider: true },
                    { header: 'Purpose of Visit' },
                    { id: 'ByPurposeOfVisit', value: 'Purpose of visit wise' },
                ],
                ReportInclusionCats: [
                    { id: 'indian', value: 'Only Indian' },
                    { id: 'exclude', value: ' Indian Exclusive ' },
                    { id: 'include', value: 'Indian Inclusive ' },
                ],
                ReportCats: [],
                dialogDelete: false,
                deleteFormData: [],
                isLoading: false,
                formdata: [],
                historyData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    ReportType: "",
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 10,
                    FromDate: null,
                    ToDate: null,
                    isIndian: "",
                    ReportCat: "",
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    menu1: false,
                    menu2: false,
                    /* breadcrumbsItem: [
                         {
                             text: 'Dashboard',
                             disabled: false,
                             to: 'MountaineeringDashboard',
                             exact: true

                         },
                         {
                             text: 'Mountaineering History Information',
                             disabled: true,
                         },
                     ],*/
                    dynamicComponent: {
                        current: 'HistoryForm'
                    }
                },
                yearFromDate: new Date().getFullYear(),
                yearToDate: new Date().getFullYear()

            };
        }
    }
</script>

<style scoped lang="scss">
    $blue: #265b93;

    .myCustomTable {
        text-align: center;
        width: fit-content;
        min-width: 100%;

        thead, tbody {
            width: 100%;
        }

        thead {
            width: 100%;
            background-color: $blue;
            color: white;
        }

        td, th {
            padding: 1rem
        }

        border: 1px solid #eee;

        tr, td, th {
            border: 1px solid #eee;
        }
    }

    .table-container {
        width: 100%;
    }

    .headerBackground {
        background-color: $blue;
        color: white;
    }
    .sticky-table-header {
        thead {
            position: sticky;
            top: 60px;
            z-index: 1;
        }
        }
  
</style>